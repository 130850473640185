import _each from 'lodash-es/each';
import _has from 'lodash-es/has';
import _isNull from 'lodash-es/isNull';
import _map from 'lodash-es/map';
import _isEmpty from 'lodash-es/isEmpty';

import { renameKeys } from '../utils';

export class BaseModel {
  static nestedModels: { [key: string]: any } = {};
  static mappings: { [key: string]: string } = {};

  constructor(data: Object) {
    if (_isEmpty(data)) {
      return;
    }

    const mappings = this['constructor']['mappings'];

    if (mappings) {
      data = renameKeys(data, mappings);
    }

    const nestedModels = this['constructor']['nestedModels'];

    _each(data, (value, key) => {
      if (_has(nestedModels, key) && !_isNull(value)) {
        this[key] = Array.isArray(data[key])
          ? _map(data[key], (submodelValue) => {
              return new nestedModels[key](submodelValue);
            })
          : new nestedModels[key](data[key]);
      } else {
        this[key] = value;
      }
    });
  }
}
